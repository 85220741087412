import { AxiosResponse } from 'axios';
import { Conversation } from '../model';
import { tavusHttpService } from 'shared/api';
import { ENVIRONMENT } from 'shared/model';
import { userStore } from 'entities/user';
import { logger } from 'shared/lib';

const tavusConversationsEndpoint = '/conversation/';

export const conversationApi = {
  createConversation: async (personaId: string, key?: string) => {
    const { data }: AxiosResponse<Conversation> = await tavusHttpService.post(
      tavusConversationsEndpoint + `start/${personaId}`,
      {},
      {
        headers: {
          Authorization:
            (userStore.getState().user?.internalKey || key) ??
            (ENVIRONMENT === 'development' || ENVIRONMENT === 'local')
              ? '3dd8b41b-4e2f-4465-a7fe-b5029f5851e3'
              : ''
        }
      }
    );

    return data;
  },
  endConversation: async (conversationId: string) => {
    const data: AxiosResponse<any> = await tavusHttpService.post(
      `${tavusConversationsEndpoint}${conversationId}/end`
    );

    return data;
  }
};
